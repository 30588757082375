// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicFullarrow,
  DefaultFullarrowProps
} from "./plasmic/d_d_npc_generator/PlasmicFullarrow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface FullarrowProps extends DefaultFullarrowProps {
  click: React.MouseEventHandler<HTMLDivElement>;
}

function Fullarrow_(props: FullarrowProps, ref: HTMLElementRefOf<"div">) {
  const rest = props as DefaultFullarrowProps;

  return <PlasmicFullarrow
    root={{ ref }}
    onClick={ props.click }
    {...rest}
  />;
}

const Fullarrow = React.forwardRef(Fullarrow_);
export default Fullarrow;
